<template>
  <div class="bingoMachine">
    <div :class="(isTvBox) ? 'spriteMain spriteTvBox' : 'spriteMain'">
      <div
        v-if="isTvBox"
        id="sprite-container"
        class="static-container"
      >
        <div id="sprite-image" class="bolilleroStatic"></div>
      </div>
      <div
        v-else
        id="sprite-container"
        class="sprite-container"
      >
        <div id="sprite-image" class="loop"></div>
      </div>
    </div>
    <div class="bolilleroContainer">
      <div class="ballsContainer">
        <div
          v-for="index in totalBalls"
          :id="'smallBall-'+(index - 1)"
          v-bind:key="index"
          :class="'smallBall ball_image_'+((index-1) % totalImages)"
        >
          <div
            :id="'textBall-' + (index - 1)"
            class="textBall"
          >
            {{(index >= 10) ? (index) : (`0${index}`)}}
          </div>
        </div>
      </div>
      <div class="track-container"></div>
    </div>
    <div class="bigBallContainer">
      <div
        id="bigBall"
        :class='"bigBall "+bigBallImage'
      >
        <div
          id="textBigBall"
          class="textBigBall"
        >
          {{bigBallNumber}}
        </div>
      </div>
      <div class="bigBallFront"></div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-dynamic-require */
import gsap from 'gsap';
import constants from '../constants/constants';

const ballPositionsMap = [
  { pos: { x: '77%', y: '59%' }, level: 3, rot: 1440 },
  { pos: { x: '57%', y: '59%' }, level: 3, rot: 1080 },
  { pos: { x: '37%', y: '59%' }, level: 3, rot: 720 },
  { pos: { x: '17%', y: '59%' }, level: 3, rot: 360 },
  { pos: { x: '-2%', y: '59%' }, level: 3, rot: 0 },
  {
    pos: { x: '0%', y: '49%' }, level: 3, rot: 0, medium: true,
  },
  { pos: { x: '12%', y: '41%' }, level: 2, rot: -1080 },
  { pos: { x: '32%', y: '39%' }, level: 2, rot: -720 },
  { pos: { x: '52%', y: '38%' }, level: 2, rot: -360 },
  { pos: { x: '72%', y: '36%' }, level: 2, rot: 0 },
  {
    pos: { x: '72%', y: '26%' }, level: 2, rot: 0, medium: true,
  },
  { pos: { x: '55%', y: '21%' }, level: 1, rot: 1080 },
  { pos: { x: '35%', y: '19%' }, level: 1, rot: 720 },
  { pos: { x: '15%', y: '17%' }, level: 1, rot: 360 },
];

// eslint-disable-next-line no-unused-vars
export default {
  name: 'IdleApp',
  props: {
    dataGame: Object,
    sounds: Array,
  },
  data() {
    return {
      animationBolilleroID: null,
      ballOntrack: 0,
      ballsInBoard: [],
      bigBallImage: '',
      bigBallNumber: '',
      currentIndexBall: 0,
      isRecoverGame: false,
      isTvBox: false,
      maxBallOnTrack: 14,
      moveOnePositionId: null,
      raffleData: this.dataGame.results || [],
      raffledBalls: [],
      soundList: this.sounds || [],
      speedBolillero: 60,
      startBingoSound: false,
      totalBalls: 90,
      totalImages: 10,
      totalSpriteDesktop: 60,
    };
  },
  beforeMount() {
    this.isTvBox = this.$store.getters.getChannel;
  },
  mounted() {
    const spriteSheet = document.getElementById('sprite-image');

    if (spriteSheet) {
      this.startAnimation(spriteSheet);
    }

    if (this.dataGame?.results?.length) {
      this.configAnimations();

      if (this.dataGame.isRecoverRaffle) {
        this.isRecoverGame = true;
        this.recoverGame();
      } else {
        this.newBall();
      }
    }
  },
  computed: {
    muteActive() {
      return this.$store.getters.getActiveMute;
    },
  },
  watch: {
    dataGame() {
      const raffleData = this.dataGame.results;
      this.raffleData = raffleData;

      if (raffleData?.length > this.ballsInBoard.length && !this.isRecoverGame) {
        this.newBall();
      }

      return !!this.raffleData.length;
    },
  },
  methods: {
    notifyNewBall(idBall) {
      this.$store.dispatch(constants.ADD_NEW_IDLE_BALL, idBall);
    },
    recoverGame() {
      this.raffleData.forEach((idBall) => {
        this.ballsInBoard.push(idBall);
        this.notifyNewBall(idBall);
      });

      this.isRecoverGame = false;
      this.$store.dispatch(constants.UPDATE_RECOVER_GAME, false);
    },
    newBall() {
      if (!this.raffleData.length) {
        this.clearData();
        return;
      }

      const newBallCallback = () => {
        const index = this.ballsInBoard.length;
        const idBall = this.raffleData[index];
        const sound = this.getSound(idBall);

        this.currentIndexBall = this.raffledBalls.length;
        this.raffledBalls.push(idBall);
        this.ballsInBoard.push(idBall);
        this.ballOntrack += 1;
        // Start Animations
        this.getInBigBallAnimation(idBall);
        this.getInSmallBallAnimation(idBall);

        if (sound) {
          this.playSound(sound);
        }

        this.notifyNewBall(idBall);
      };

      if (this.ballOntrack >= this.maxBallOnTrack) {
        this.moveOnePosition(newBallCallback);
        return;
      }

      newBallCallback && newBallCallback();
    },
    setMapValues(ball) {
      const map = ballPositionsMap[ball.index];

      ball.map = map;
      ball.level = map.level;
      ball.medium = map?.medium || false;
    },
    /* The animations keep running without focus */
    configAnimations() {
      gsap.ticker.lagSmoothing(false);

      if (this.isTvBox) {
        gsap.ticker.fps(55);
      }
    },
    clearData() {
      this.killAnimations();
      clearTimeout(this.moveOnePositionId);
      this.ballsInBoard = [];
      this.bigBallImage = '';
      this.bigBallNumber = '';
      this.currentIndexBall = 0;
      this.moveOnePositionId = null;
      this.raffleData = this.dataGame.results || [];
      this.raffledBalls = [];
      this.startBingoSound = false;
    },
    killAnimations() {
      const smallBalls = document.querySelectorAll('.smallBall');
      if (smallBalls && smallBalls.length > 0) {
        smallBalls.forEach((smallBall) => this.resetValueBall(smallBall));
      }
    },
    resetValueBall(ball) {
      if (!ball) { return; }

      ball.index = 0;
      ball.style.visibility = 'hidden';
      gsap.killTweensOf(ball);
    },
    getSound(nameSound) {
      if (!this.soundList.length) { return false; }

      const audio = this.soundList[nameSound - 1];
      return audio;
    },
    playSound(audio) {
      audio.play()
        .catch((param) => {
          // eslint-disable-next-line no-console
          console.info('Audio Error', param);
        });
    },
    // ********** ANIMATIONS ********** /
    // Stop animation of Bolillero Bingo
    stopAnimation() {
      clearInterval(this.animationBolilleroID);
      this.animationBolilleroID = null;
    },
    // Start animation of Bolillero Bingo
    startAnimation(spriteSheet) {
      if (this.isTvBox) {
        return;
      }

      const speed = this.speedBolillero; // in millisecond(ms)
      const totalFrames = this.totalSpriteDesktop - 1;
      let indexSprite = 0;

      this.animationBolilleroID = setInterval(() => {
        spriteSheet.classList.add(`loop-layer-${indexSprite}`);
        if (indexSprite >= 1) {
          spriteSheet.classList.remove(`loop-layer-${indexSprite - 1}`);
        } else if (indexSprite === 0) {
          spriteSheet.classList.remove(`loop-layer-${totalFrames}`);
        }
        indexSprite = (indexSprite >= totalFrames) ? 0 : (indexSprite + 1);
      }, speed);
    },
    // ***** TRACK ANIMATION *****/
    getInBigBallAnimation(idBall) {
      const bigBall = document.getElementById('bigBall');

      if (bigBall) {
        this.bigBallImage = `ball_image_${(idBall - 1) % this.totalImages}`;
        this.bigBallNumber = (idBall >= 10) ? idBall : `0${idBall}`;

        bigBall.classList.remove('scalaInAnimation');

        this.getInBigBallAnimationID = setTimeout(() => {
          clearTimeout(this.getInBigBallAnimationID);
          this.getInBigBallAnimationID = null;

          bigBall.classList.add('scalaInAnimation');
        }, 250);
      }
    },
    getInSmallBallAnimation(idBall) {
      const nameId = `smallBall-${idBall - 1}`;
      const smallBall = document.getElementById(nameId);

      if (smallBall) {
        const index = (this.currentIndexBall >= ballPositionsMap.length)
          ? this.ballOntrack - 1 : this.currentIndexBall;
        const tl = gsap.timeline();

        smallBall.index = index;
        smallBall.tween = tl;
        smallBall.style.visibility = 'visible';
        this.setMapValues(smallBall);

        const callback = (ball, tween) => {
          if (ball.level === 1) {
            this.movementEndBall(ball, tween);
          } else {
            this.movementBallLevel1(ball, tween);
          }
        };

        tl.to(smallBall, {
          duration: 0.8,
          ease: 'bounce.out',
          top: '16%',
          callbackScope: this,
          overwrite: true,
          onComplete: () => callback(smallBall, tl),
        });
      }
    },
    movementBallLevel1(ball, tween) {
      if (!ball && !tween) return;

      tween.to(ball, {
        duration: 0.8,
        ease: 'sine.inOut',
        left: '72%',
        top: '22%',
        rotation: 1440,
        transformOrigin: '50% 50%',
        onComplete: () => this.movementDownLevel1(ball, tween),
        callbackScope: this,
      });
    },
    movementBallLevel2(ball, tween) {
      if (!ball && !tween) return;

      tween.to(ball, {
        duration: 0.8,
        ease: 'sine.inOut',
        left: '3%',
        top: '42%',
        rotation: -1440,
        onComplete: () => this.movementDownLevel2(ball, tween),
      });
    },
    movementEndBall(ball, tween, updatePosition) {
      if (!ball && !tween) return;

      const { map } = ball;
      const animParams = {
        duration: (updatePosition) ? 0.5 : 0.8,
        ease: (updatePosition) ? 'sine.inOut' : 'sine.in',
        top: map.pos.y,
        left: map.pos.x,
      };

      if (map.rot !== 0) animParams.rotation = map.rot;

      tween.to(ball, animParams);
    },
    movementDownLevel1(ball, tween) {
      if (!ball && !tween) return;

      const callback = () => {
        if (ball.level > 2) {
          this.movementBallLevel2(ball, tween);
        } else {
          this.movementEndBall(ball, tween);
        }
      };

      const { map } = ball;
      const top = (map?.medium && map?.level === 2) ? '26%' : '36%';
      tween.to(ball, {
        duration: 0.3,
        ease: 'bounce.out',
        top,
        left: '72%',
        onComplete: () => callback(ball, tween),
      });
    },
    movementDownLevel2(ball, tween) {
      if (!ball && !tween) return;

      const { map } = ball;
      tween.to(ball, {
        duration: 0.3,
        ease: 'bounce.out',
        top: (map?.medium) ? '49%' : '59%',
        left: (map?.medium) ? '0%' : '-2%',
        onComplete: () => this.movementEndBall(ball, tween),
      });
    },
    outBallAnimation(ball, tween) {
      if (!ball && !tween) return;

      tween.to(ball, {
        duration: 0.3,
        ease: 'none',
        scale: 0,
        onComplete: () => tween.kill(),
      });
    },
    moveOnePosition(callback) {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.raffledBalls.length; index++) {
        const idBall = this.raffledBalls[index];
        const nameId = `smallBall-${idBall - 1}`;
        const ball = document.getElementById(nameId);

        if (ball && ball.style.visibility === 'visible') {
          const newIndexball = ball.index - 2;
          const tween = ball.tween || gsap.timeline();

          if (newIndexball >= 0) {
            const updatePosition = true;
            ball.index = newIndexball;
            ball.map = ballPositionsMap[newIndexball];
            this.movementEndBall(ball, tween, updatePosition);
          } else {
            this.outBallAnimation(ball, tween);
          }
        }
      }
      this.ballOntrack -= 2;

      this.moveOnePositionId = setTimeout(() => {
        clearTimeout(this.moveOnePositionId);
        this.moveOnePositionId = null;

        callback && callback();
      }, 250);
    },
  },
};
</script>
