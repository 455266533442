/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
import APP_CONFIG from '../config/app_config';
import APP_UTILS from '../config/app_utils';
import APP_TEXTS from '../languages/language_PT';
import constants from '../constants/constants';

export default class CommManager {
  constructor() {
    this.initialized = false;
    this.store = null;

    // Set values
    this.currentDraw = null;
    this.currentMatchId = null;
    this.currentPositionRecovered = 0;
    this.dataUrl = null;
    this.isRecoverRaffle = false;
    this.lastMatchIdDisplayed = null;
    this.livePreProccessValidated = false;
    this.liveRaffleData = null;
    this.preprocessed = false;
    this.previousLiveRaffleData = null;
    this.previousRafflesData = null;
    this.runningTables = [];
    this.tableId = null;
    this.upcomingRafflesData = null;

    /* Listeners */
    this.listenerLiveRaffleData = null;
    this.listenerPreviousRafflesData = null;
    this.listenerRunningTables = null;
    this.listenerUpcomingRafflesData = null;
    /* Timers */
    this.timerRealToLiveRaffle = null;
    this.timerLiveRaffle = 1000;
    this.timerDefaultRequest = 5000;
    this.accumulatedPreProcessedTime = 1;
    this.offsetTimeLive = 0;

    /* failed request */
    this.maxRequestError = 2;
    this.runningTablesRequestError = 0;
  }

  initialize(store, urlParams) {
    this.initialized = true;
    this.store = store;
    this.getUrlParams(urlParams);

    /* Start Requests */
    /* this.getImages(); */
    this.dispatchRequestLiveRaffle();
    this.dispatchRequestUpcomingRaffles();
    this.dispatchRequestPreviousRaffles();
  }

  getUrlParams(urlParams) {
    const url = urlParams;
    /* remove first and last character */
    let params = url.indexOf('/') === 0 ? url.substring(1) : url;
    const lengthURL = params.length - 1;
    params = (params.lastIndexOf('/') === lengthURL) ? params.substring(0, lengthURL) : params;
    params = params.split('/');

    let paramsObj = {};
    let paramsQuery = null;
    let preprocessedMode = false;

    /* Array to Object */
    for (let i = 0; i < params.length; i++) {
      const key = params[i];
      const value = params[i + 1] || null;
      const element = { [key]: value };
      paramsObj = { ...paramsObj, ...element };
      i += 1;
    }

    /* validate operator and table params */
    if (paramsObj.operator && paramsObj.table) {
      paramsQuery = `${paramsObj.operator}_${paramsObj.table}`;
      this.dataUrl = paramsObj;
      preprocessedMode = (paramsObj?.slow && paramsObj.slow > 0);
    } else {
      const errorData = {
        type: 'Error',
        component: constants.COMPONENT_BINGO,
        content: APP_TEXTS.urlParamsInvalid,
        show: true,
      };
      this.store.dispatch(constants.SHOW_ERROR_MESSAGE, errorData);
    }

    this.tableId = paramsQuery;
    this.preprocessed = preprocessedMode;
  }

  parseLiveDraws() {
    let liveRaffleData = null;

    /* Validate if the raffle is preprocessed else it's live */
    if (this.liveRaffleData?.length >= 0) {
      if (this.liveRaffleData.length) {
        liveRaffleData = this.liveRaffleData.shift();
      }
    } else {
      liveRaffleData = this.liveRaffleData;
    }

    const data = liveRaffleData && liveRaffleData?.payload;
    if (!data) {
      return false;
    }

    data.table = liveRaffleData && liveRaffleData?.table;
    data.stream = liveRaffleData && liveRaffleData?.stream;
    data.play = liveRaffleData && liveRaffleData?.play;

    this.previousLiveRaffleData = data;

    if (data.play === constants.STATE_DRAW_RAFFLE_COMPLETE) {
      this.lastMatchIdDisplayed = this.currentMatchId;
      this.liveRaffleData = null;
      this.livePreProccessValidated = false;
      this.accumulatedPreProcessedTime = 1;
      this.offsetTimeLive = 0;
      this.previousLiveRaffleData = null;
    }

    const winners = [];
    const awardedPrizes = (data.awardedPrizes) ? [...data.awardedPrizes] : [];

    for (let index = 0; index < awardedPrizes.length; index++) {
      const prize = awardedPrizes[index];

      prize?.winners?.forEach((w) => {
        const winner = w;
        const cardValue = winner.cardValues.filter((value) => (value > 0)) || [];
        winner.allCardValues = [...cardValue];
        winner.smallCardValues = cardValue.splice(0, 6);
        winners.push(winner);

        return true;
      });

      if (prize.prizeId === APP_CONFIG.PRIZE_ID_JACKPOT) {
        const bingoPrize = awardedPrizes[index - 1] || null;

        if (bingoPrize?.prizeId === APP_CONFIG.PRIZE_ID_BINGO) {
          prize.name = `${bingoPrize.name} + ${prize.name}`;
          prize.netValue += bingoPrize.netValue;
          prize.originalValue += bingoPrize.originalValue;
          prize.value += bingoPrize.value;
          prize.isJackpot = true;
          bingoPrize.hasJackpot = true;
        }
      }
    }

    let removeJackpotFromNP = false;
    let jackpotData = {
      value: 0,
      prizeId: APP_CONFIG.PRIZE_ID_JACKPOT,
    };

    let nextPrizes = data?.nextPrizes?.filter((prize) => {
      if (prize.value > 0) {
        if (prize.prizeId === jackpotData.prizeId) {
          jackpotData = { ...jackpotData, ...prize };
          removeJackpotFromNP = true;
        }

        // eslint-disable-next-line no-param-reassign
        prize.value = APP_UTILS.formatCurrency(prize.value);
        return true;
      }
      return false;
    }) || [];

    if (removeJackpotFromNP || nextPrizes.length > 3) {
      nextPrizes = nextPrizes.splice(0, 3);
    }

    let nextPrizesToPreRaffle = [...nextPrizes];
    nextPrizesToPreRaffle = nextPrizesToPreRaffle.filter((p) => p.prizeId !== APP_CONFIG.PRIZE_ID_JACKPOT);

    const prizeDisabledAtById = data?.prizeDisabledAtById || [];
    const accumulatedPrizeBalls = (prizeDisabledAtById[APP_CONFIG.PRIZE_ID_JACKPOT]) || 0;
    const participants = (data.ranking) ? [...data.ranking] : [];

    const currentDraw = {
      awardedPrizes: data.awardedPrizes || [],
      currentRaffleData: {
        accumulatedPrizeBalls,
        cardValue: APP_UTILS.formatCurrency(data.cardValue),
        closeBetsTime: data.closeBetsTime || false,
        matchId: data.matchId,
        matchName: data.matchName,
        nextPrizes,
        nextPrizesToPreRaffle,
        startTime: data.startTime,
        timestamp: (data.stream && data.stream.streamTime) || false,
      },
      participants,
      startGame: {
        accumulatedPrizeBalls,
        currentBall: (data.results && data.results.length) || 0,
        currentPositionRecovered: this.currentPositionRecovered,
        isRecoverRaffle: this.isRecoverRaffle,
        results: data.results || [],
      },
      statusPlay: data.play,
      winners,
    };

    if (jackpotData && jackpotData.value > 0) {
      const accumulatedPrize = APP_UTILS.formatCurrency(jackpotData.value);
      currentDraw.currentRaffleData.accumulatedPrize = accumulatedPrize;
      currentDraw.startGame.accumulatedPrize = accumulatedPrize;
    }

    this.currentDraw = currentDraw;
    this.currentMatchId = data.matchId;
    this.store.dispatch(constants.UPDATE_DATA_FROM_SERVER, currentDraw);

    return currentDraw;
  }

  parseUpcomingDraws() {
    const parsedData = [];
    const draws = this.upcomingRafflesData || [];
    const currentDate = Date.now();

    for (let i = 0; i < draws.length; i++) {
      const draw = draws[i];
      const dateDraw = new Date(draw.startTime).getTime();
      if (dateDraw >= currentDate) {
        parsedData.push(draw);
      }
    }

    if (parsedData.length) {
      const sortedData = parsedData.sort((a, b) => {
        const aDate = new Date(a.startTime).getTime();
        const bDate = new Date(b.startTime).getTime();
        return aDate - bDate;
      });

      // New data for upcoming draws
      this.store.dispatch(constants.UPDATE_DATA_UPCOMINGDRAW, sortedData);
    } else {
      this.upcomingRafflesData = null;
    }
  }

  parsePreviousDraws() {
    const previousRaffles = [...this.previousRafflesData] || [];
    let previousDataParse = [];

    if (previousRaffles.length) {
      for (let index = 0; index < previousRaffles.length; index++) {
        const data = previousRaffles[index];
        const raffle = { ...data };
        const winnersByPrize = [
          {
            name: APP_CONFIG.PRIZE_NAME_QUADRA,
            id: APP_CONFIG.PRIZE_ID_QUADRA,
            winners: [],
          },
          {
            name: APP_CONFIG.PRIZE_NAME_LINHA,
            id: APP_CONFIG.PRIZE_ID_LINHA,
            winners: [],
          },
          {
            name: APP_CONFIG.PRIZE_NAME_BINGO,
            id: APP_CONFIG.PRIZE_ID_BINGO,
            winners: [],
          },
        ];

        if (raffle.awardedPrizes && raffle.awardedPrizes.length) {
          const { awardedPrizes } = raffle;
          for (let indexAP = 0; indexAP < awardedPrizes.length; indexAP++) {
            const awardedPrize = { ...awardedPrizes[indexAP] };
            if (awardedPrize.prizeId <= APP_CONFIG.PRIZE_ID_BINGO) {
              const {
                cardId,
                winnerId,
                prizeId,
              } = awardedPrize;

              winnersByPrize[prizeId - 1].winners.push({
                cardId,
                winnerId,
              });
            }
          }
        }
        raffle.winnersByPrize = [...winnersByPrize];
        previousDataParse.push(raffle);
      }

      if (previousDataParse.length) {
        previousDataParse = previousDataParse.sort((a, b) => {
          const aDate = new Date(a.matchDate).getTime();
          const bDate = new Date(b.matchDate).getTime();
          return bDate - aDate;
        });
      }

      // New data for previous draws
      this.store.dispatch(constants.UPDATE_DATA_PREVIOUSDRAW, previousDataParse);
    }
  }

  dispatchRequestLiveRaffle() {
    const timeout = this.getTimeoutLiveRaffle();

    this.listenerLiveRaffleData = setTimeout(() => {
      clearTimeout(this.listenerLiveRaffleData);
      this.listenerLiveRaffleData = null;

      this.getLiveRaffle()
        .then(async (response) => {
          if (response) {
            if (response?.length && response[0]?.payload?.matchId === this.lastMatchIdDisplayed) {
              this.dispatchRequestLiveRaffle();
              return;
            }

            this.liveRaffleData = response;

            await this.validatePreProccessRaffle();
            this.parseLiveDraws();
            this.dispatchRequestLiveRaffle();
          }
        });
    }, timeout);
  }

  dispatchRequestPreviousRaffles() {
    this.listenerPreviousRafflesData = setTimeout(() => {
      clearTimeout(this.listenerPreviousRafflesData);
      this.listenerPreviousRafflesData = null;

      if (this.store.getters.showGame) {
        this.dispatchRequestPreviousRaffles(false);
        return;
      }

      this.getPreviousRaffles()
        .then((response) => {
          if (response && response.length) {
            this.previousRafflesData = response;
            this.parsePreviousDraws();
          }
        });
    }, this.timerRealToLiveRaffle || this.timerDefaultRequest);
  }

  dispatchRequestUpcomingRaffles() {
    this.listenerUpcomingRafflesData = setTimeout(() => {
      clearTimeout(this.listenerUpcomingRafflesData);
      this.listenerUpcomingRafflesData = null;

      if (this.store.getters.showGame && this.upcomingRafflesData !== null) {
        this.dispatchRequestUpcomingRaffles();
        return;
      }

      this.getUpcomingRaffles()
        .then((response) => {
          if (response && response.length) {
            this.upcomingRafflesData = response;
            this.parseUpcomingDraws();
          }
        });
    }, this.timerRealToLiveRaffle || this.timerDefaultRequest);
  }

  getTimeoutLiveRaffle() {
    let seconds = 0;

    if (this.liveRaffleData?.length && this.previousLiveRaffleData !== null) {
      const offsetTime = this.previousLiveRaffleData?.offsetTime;
      seconds = (offsetTime > 1) ? offsetTime - this.accumulatedPreProcessedTime : 1;
      this.accumulatedPreProcessedTime += seconds;

      if (this.offsetTimeLive > 0 && this.offsetTimeLive > offsetTime) {
        seconds = 1;
      } else {
        this.offsetTimeLive = 0;
      }
    } else if (this.liveRaffleData?.play === constants.STATE_DRAW_ACCEPTING_BETS) {
      const closeBetsTime = this.liveRaffleData?.payload?.closeBetsTime;
      seconds = (closeBetsTime > 10) ? Math.floor(closeBetsTime / 3) : 1;
    }

    this.timerRealToLiveRaffle = (seconds > 1) ? (seconds * 1000) : this.timerLiveRaffle;
    return this.timerRealToLiveRaffle;
  }

  updateIsRecoverRaffle(recovered) {
    this.isRecoverRaffle = recovered;
  }

  async validatePreProccessRaffle() {
    if (this.liveRaffleData && !this.livePreProccessValidated) {
      this.livePreProccessValidated = true;
      const firstRaffle = (this.liveRaffleData?.length) ? this.liveRaffleData[0] : this.liveRaffleData;
      const now = Date.now();

      if (now - firstRaffle.payload.startTime < 15000) {
        return;
      }

      const currentPosition = await this.getCurrentPositionLiveRaffle();

      if (currentPosition >= 3) {
        this.offsetTimeLive = firstRaffle.payload.offsetTime + currentPosition;
        this.isRecoverRaffle = true;
        this.currentPositionRecovered = currentPosition;

        if (this.liveRaffleData?.length) {
          const previousLiveRaffleData = this.liveRaffleData[currentPosition];
          previousLiveRaffleData.table = previousLiveRaffleData && previousLiveRaffleData?.table;
          previousLiveRaffleData.stream = previousLiveRaffleData && previousLiveRaffleData?.stream;
          previousLiveRaffleData.play = previousLiveRaffleData && previousLiveRaffleData?.play;

          this.offsetTimeLive = previousLiveRaffleData.payload.offsetTime;
          this.accumulatedPreProcessedTime = this.offsetTimeLive;
          this.previousLiveRaffleData = previousLiveRaffleData;
          this.liveRaffleData.splice(0, currentPosition);
        }
      }
    }
  }

  /* Service request */
  async getCurrentPositionLiveRaffle() {
    let urlBase = APP_CONFIG.BASE_API;
    urlBase += APP_CONFIG.ENDPOINT_TABLE_STATUS;
    urlBase += this.tableId;

    const headers = new Headers({ 'Content-Type': 'text/plain' });
    const requestOptions = { ...constants.REQUEST_OPTIONS };
    requestOptions.headers = headers;

    const returnedData = await fetch(urlBase, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result && result.payload) {
          return result.payload?.results?.length || 0;
        }

        return 0;
      })
      .catch(() => 0);

    return returnedData;
  }

  /* Service request */
  async getLiveRaffle() {
    if (!this.tableId) {
      return false;
    }

    if (this.store.getters.showGame && this.liveRaffleData?.length >= 0) {
      return this.liveRaffleData;
    }

    let urlBase = APP_CONFIG.BASE_API;
    urlBase += APP_CONFIG.ENDPOINT_TABLE_STATUS;
    urlBase += this.tableId;
    urlBase += (this.preprocessed) ? APP_CONFIG.TABLE_PREPROCESSED : '';

    const errorData = {
      type: 'Error',
      component: constants.COMPONENT_BINGO,
      content: APP_TEXTS.tableInMaintenance,
      show: true,
    };

    const headers = new Headers({ 'Content-Type': 'text/plain' });
    const requestOptions = { ...constants.REQUEST_OPTIONS };
    requestOptions.headers = headers;

    const returnedData = await fetch(urlBase, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if ((result && result.payload) || (this.preprocessed && result?.length)) {
          return result;
        }

        this.runningTablesRequestError += 1;
        if (this.runningTablesRequestError >= this.maxRequestError) {
          this.store.dispatch(constants.SHOW_ERROR_MESSAGE, errorData);
        }

        this.dispatchRequestLiveRaffle();
        console.error('TABLE STATUS  >>> Error in data...');
        return false;
      })
      .catch((error) => {
        console.error('GetLiveRaffle Catch Error', error);

        this.runningTablesRequestError += 1;
        if (this.runningTablesRequestError >= this.maxRequestError) {
          this.store.dispatch(constants.SHOW_ERROR_MESSAGE, errorData);
        }

        this.dispatchRequestLiveRaffle(true);
        return false;
      });

    return returnedData;
  }

  /* Service request */
  async getUpcomingRaffles() {
    if (!this.tableId) {
      this.dispatchRequestUpcomingRaffles();
      return false;
    }

    const table = this.tableId;
    let urlBase = APP_CONFIG.BASE_API;
    urlBase += APP_CONFIG.ENDPOINT_GET_NEXT_MATCHES;
    urlBase += table;

    const headers = new Headers({ 'Content-Type': 'text/plain' });
    const requestOptions = { ...constants.REQUEST_OPTIONS };
    requestOptions.headers = headers;

    const returnedData = await fetch(urlBase, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result && result.nextMatches) {
          this.dispatchRequestUpcomingRaffles();
          return result.nextMatches;
        }

        // Return error
        this.dispatchRequestUpcomingRaffles();
        console.error('UPCOMING RAFFLES >>> Error in data...');
        return false;
      })
      .catch((error) => {
        console.error('GetUpcomingRaffles Catch Error', error);

        this.dispatchRequestUpcomingRaffles();
        return false;
      });

    return returnedData;
  }

  /* Service request */
  async getPreviousRaffles() {
    if (!this.tableId) {
      this.dispatchRequestPreviousRaffles();
      return false;
    }

    const table = this.tableId;
    let urlBase = APP_CONFIG.BASE_API;
    urlBase += APP_CONFIG.ENDPOINT_GET_PREVIOUS_MATCHES;
    urlBase += table;

    const headers = new Headers({ 'Content-Type': 'text/plain' });
    const requestOptions = { ...constants.REQUEST_OPTIONS };
    requestOptions.headers = headers;

    const returnedData = await fetch(urlBase, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          this.dispatchRequestPreviousRaffles();
          return result;
        }

        /* Return error */
        this.dispatchRequestPreviousRaffles();
        console.error('PREVIOUS RAFFLES  >>> Error in data...');
        return false;
      })
      .catch((error) => {
        console.error('GetPreviousRaffles Catch Error', error);

        this.dispatchRequestPreviousRaffles();
        return false;
      });

    return returnedData;
  }

  async getImages() {
    if (!this.dataUrl) { return false; }

    const { table } = this.dataUrl;
    let urlBase = APP_CONFIG.BASE_ADS_API;
    urlBase += APP_CONFIG.ENDPOINT_GET_IMAGES.replace(':table_id', table);

    const headers = new Headers({ 'Content-Type': 'text/plain' });
    const requestOptions = { ...constants.REQUEST_OPTIONS };
    requestOptions.headers = headers;

    const returnedData = await fetch(urlBase, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.imagens?.length) {
          if (result.urlJackpot && result.urlJackpot !== '' && result.valorAtualJackpot > 0) {
            const firstImage = result.imagens[0];
            const addJackpot = {
              imagenId: result.tableId,
              isJackpot: true,
              url: result.urlJackpot,
              tempoExibicao: firstImage.tempoExibicao,
              valorAtualJackpot: APP_UTILS.formatCurrency(result.valorAtualJackpot),
              qtdAtualBolasJackpot: result.qtdAtualBolasJackpot,
            };

            result.imagens.push(addJackpot);
          }

          let totalTempoExibicao = 0;
          result.imagens.forEach((img) => {
            totalTempoExibicao += img.tempoExibicao;
          });

          // eslint-disable-next-line no-param-reassign
          result.totalTempoExibicao = Number(totalTempoExibicao * 1000);

          this.store.dispatch(constants.ADD_ADS, result);

          return true;
        }

        // Return error
        console.info('get_propagandas >>> the tableId has no images to display in the carousel...');
        return false;
      })
      .catch((error) => {
        console.error('GetImages catch error:', error);
        return false;
      });

    return returnedData;
  }
}
