const constants = {
  SET_ACTIVE_MUTE: 'setActiveMute',
  SET_ADS_DATA: 'setAdsData',
  SET_CHANNEL: 'setChannel',
  SET_DATA_FROM_SERVER: 'setDataFromServer',
  SET_INITIALIZED_APP: 'setInitializedApp',
  SET_IS_APP: 'setIsApp',
  SET_MESSAGE_ERROR: 'setMessageError',
  SET_NEW_IDLE_BALL: 'setNewIdleBall',
  SET_NEXT_DATE_DRAW: 'setNextDateDraw',
  SET_PREVIOUS_RAFFLES: 'setPreviousRaffles',
  SET_RECOVER_RAFFLE: 'setRecoverRaffle',
  SET_UPCOMING_DRAW: 'setUpcomingDraw',

  ACTIVE_MUTE: 'activeMute',
  ADD_ADS: 'addAds',
  ADD_NEW_IDLE_BALL: 'addNewIdleBall',
  INITIALIZE_APP: 'initializeApp',
  IS_APP: 'isApp',
  UPDATE_DATA_FROM_SERVER: 'updateDataFromServer',
  UPDATE_DATA_PREVIOUSDRAW: 'updateDataPreviousDraw',
  UPDATE_DATA_UPCOMINGDRAW: 'updateDataUpcomingDraw',
  UPDATE_RECOVER_GAME: 'updateRecoverGame',

  STATE_DRAW_ACCEPTING_BETS: 'AcceptingBets',
  STATE_DRAW_NO_MORE_BETS: 'NoMoreBets',
  STATE_DRAW_PLAY_CLOSED: 'PlayClosed',
  STATE_DRAW_PLAY_OPEN: 'PlayOpen',
  STATE_DRAW_RAFFLE_COMPLETE: 'RaffleComplete',
  STATE_DRAW_RAFFLING: 'Raffling',

  SHOW_ERROR_MESSAGE: 'showErrorMessage',

  COMPONENT_GENERIC: 'app',
  COMPONENT_BINGO: 'bingo',
  COMPONENT_FUTURE_PLAYS: 'upcomingDraw',

  REQUEST_OPTIONS: {
    method: 'GET',
    cache: 'no-cache',
    redirect: 'follow',
  },
};

export default constants;
