<template>
  <div
    class="containerMainDialog"
    v-show="prize && showing"
  >
    <div class="coinsContainer"
      v-show="!isTvBox"
    >
      <div id="sprite-coins-1" class="prize"></div>
      <div id="sprite-coins-2" class="prize"></div>
      <div id="sprite-coins-3" class="prize"></div>
    </div>
    <div
      id="backgroundDialog" class="backgroundDialog zoomInAnimation"
    >
      <div class="rowDialog row1">
        <div :class="'centerAbsolute text-bold colorWhite pt-3' + classPrizeTitle">
          {{getPhrase('prize')}}
          {{String(prize.name).toUpperCase()}}!
        </div>
      </div>
      <div class="rowDialog row2">
        <div class="text-subtitle-large text-bold colorWhite pb-1">
          {{getPhrase('winners')}}
        </div>
      </div>
      <div class="rowDialog row3">
        <div
          id="winnerCard"
          :class="classWinnerCard"
        >
          <div
            v-for="(winner, index) in winners"
            v-bind:key="index"
            :class="gridCardPrize"
          >
            <div
              v-for="(value, indexCard) in winner.allCardValues"
              :key="indexCard"
              class="itemPrizeContainer"
            >
              <div :class="getColorValue(value)">
                {{(value > 9) ? value : `0${value}`}}
              </div>
            </div>
            <div class="cardInfoPrize">
              <div class="text-subtitle-3 colorWhite">
                {{winner.userId}}
              </div>
              <div class="text-subtitle-3 colorOrange mt-2">
                {{`${getPhrase('ticketId')} ${winner.cardId}`}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowDialog row4">
        <div class="centerAbsolute text-title-big text-bold colorOrange">
          {{formatCurrency(prize.netValue)}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import APP_UTILS from '../config/app_utils';

export default {
  name: 'DialogPrize',
  props: {
    sounds: Array,
  },
  data() {
    return {
      classPrizeTitle: '',
      classWinnerCard: '',
      currentPrizeId: null,
      gridCardPrize: '',
      hideTimeout: null,
      indexPrizes: 0,
      isTvBox: false,
      lastPrizeId: null,
      prizesToShow: '',
      showing: false,
      showTimeout: null,
      soundsList: this.sounds || [],
      timeOnScreen: 17000,
      validatedPrize: false,
      winners: [],
    };
  },
  beforeMount() {
    this.isTvBox = this.$store.getters.getChannel;
  },
  computed: {
    prize() {
      const prize = this.$store.getters.awardedPrizes;
      const prizesList = [...prize];
      const lastPrize = (prizesList.length) ? prizesList?.pop() : [];
      const show = (lastPrize?.prizeId) ? this.showPrize(lastPrize) : false;

      return show;
    },
    results() {
      const gameData = this.$store.getters.startGame;
      const { results } = gameData;
      return results || [];
    },
  },
  methods: {
    formatCurrency(value) {
      return APP_UTILS.formatCurrency(value);
    },
    showPrize(awardedPrize) {
      const isRecoverGame = this.$store.getters.getRecoverRaffle;

      if (this.showing) {
        return this.prizesToShow;
      }

      if (awardedPrize?.prizeId !== this.lastPrizeId) {
        clearTimeout(this.showTimeout);
        this.showTimeout = null;

        if (awardedPrize?.winners?.length >= 2) {
          this.updateWinnerOnScreen(awardedPrize.winners);
        } else {
          this.winners = [...awardedPrize.winners];
          this.classWinnerCard = 'winnerCard_1';
          this.gridCardPrize = 'gridCardPrize_1';
        }

        this.prizesToShow = awardedPrize;
        this.classPrizeTitle = awardedPrize?.isJackpot ? ' text-large' : ' text-title-big';
        this.lastPrizeId = awardedPrize.prizeId;
        this.timeOnScreen = (isRecoverGame) ? 4000 : 17000;

        this.showDialog(isRecoverGame);
        return awardedPrize;
      }

      return false;
    },
    hidePrize() {
      this.hideTimeout = setTimeout(this.hideAndClear, this.timeOnScreen);
    },
    showDialog(isRecoverGame) {
      const timeout = (isRecoverGame) ? 500 : 2000;

      this.showTimeout = setTimeout(() => {
        clearTimeout(this.showTimeout);
        this.showTimeout = null;

        this.validatedPrize = true;
        this.showing = true;
        this.soundPrize(isRecoverGame);
        this.startAnimation();
        this.hidePrize();
      }, timeout);
    },
    updateWinnerOnScreen(winners) {
      clearTimeout(this.currentPrizeId);
      this.currentPrizeId = null;

      if (winners && winners.length) {
        const newWinners = [...winners];
        const maxPrize = 2;
        const startIndex = this.indexPrizes;
        const endIndex = startIndex + maxPrize;
        const showWinners = newWinners.slice(startIndex, endIndex);

        if (!showWinners.length || this.indexPrizes > winners.length) {
          return;
        }

        const winnerCard = document.getElementById('winnerCard');
        if (winnerCard) {
          this.classWinnerCard = `winnerCard_${showWinners.length}`;
          this.gridCardPrize = `gridCardPrize_${showWinners.length}`;

          winnerCard.classList.add('scalaInAnimation');
          // add listener to remove animation and listener
          winnerCard.addEventListener('animationend', (e) => {
            const animation = e.animationName || 'scalaInAnimation';
            winnerCard.classList.remove(animation);
            winnerCard.removeEventListener('animationend', () => {});
          }, { once: true });
        }

        this.indexPrizes = endIndex;
        this.winners = showWinners;

        const timeout = Number((this.timeOnScreen / winners.length) * 2);
        this.currentPrizeId = setTimeout(() => this.updateWinnerOnScreen(winners), timeout);
      }
    },
    showHaloBingo() {
      const isBingo = this.prizesToShow.prizeId === 3;
      const background = document.getElementById('backgroundDialog');
      if (background) {
        if (isBingo) {
          background.classList.add('haloBingo');
        } else {
          background.classList.remove('haloBingo');
        }
      }
    },
    hideAndClear() {
      this.stopSound();
      clearTimeout(this.showTimeout);
      clearTimeout(this.hideTimeout);
      clearTimeout(this.currentPrizeId);
      this.currentPrizeId = null;
      this.hideTimeout = null;
      this.indexPrizes = 0;
      this.prizesToShow = {};
      this.showing = false;
      this.showTimeout = null;
      this.validatedPrize = false;
    },
    getPhrase(phraseId) {
      const phrase = APP_UTILS.getPhrase(phraseId);
      return phrase;
    },
    getColorValue(value) {
      const results = [...this.results];
      const shown = results.filter((number) => number === value);
      return (shown && shown.length) ? 'cardValuePrize valueWin' : 'cardValuePrize  valueNotWin';
    },
    soundCelebration(isRecoverGame) {
      const soundName = (isRecoverGame) ? 'celebrationRecoveredGame' : 'celebration';
      const sound = this.getSound(soundName);

      if (sound) {
        this.playSound(sound);
      }
    },
    soundPrize(isRecoverGame) {
      if (this.prizesToShow?.name) {
        const soundName = `prize_${this.prizesToShow.name}`;
        const sound = this.getSound(soundName);
        if (sound) {
          this.playSound(sound);
          this.soundCelebration(isRecoverGame);
        }
      }
    },
    getSound(nameSound) {
      const audio = this.soundsList?.find((sound) => sound.name === nameSound);
      return audio && audio.source;
    },
    playSound(audio) {
      audio.currentTime = 0;
      audio.play();
    },
    stopSound() {
      const celebrationSound = this.getSound('celebration');
      celebrationSound.pause();

      const coinsSound = this.getSound('coins');
      // eslint-disable-next-line no-unused-expressions
      coinsSound?.pause();
    },
    // ********** ANIMATIONS ********** /
    // Start coins animation
    startAnimation() {
      const spriteSheet = document.querySelectorAll('.prize');

      if (!spriteSheet || !spriteSheet.length || this.isTvBox) { return; }

      this.showHaloBingo();
      spriteSheet.forEach((sprite) => {
        sprite.style.visibility = 'hidden';
        sprite.classList.add('prize-coin_1');
        this.animateCoins(sprite);

        return true;
      });

      const sound = this.getSound('coins');
      if (sound) { this.playSound(sound); }
    },
    animateCoins(sprite) {
      let indexSprite = 1;
      const speed = parseInt(40 + Math.random() * 15, 10); // millisecond(ms)
      const totalFrames = 27;
      sprite.style.visibility = 'visible';

      const intervalId = setInterval(() => {
        sprite.classList.add(`prize-coin_${indexSprite}`);
        sprite.lastClassAdded = `prize-coin_${indexSprite}`;
        if (indexSprite > 1) {
          sprite.classList.remove(`prize-coin_${indexSprite - 1}`);
        } else if (indexSprite === 1) {
          sprite.classList.remove(`prize-coin_${totalFrames}`);
        }

        indexSprite = (indexSprite >= totalFrames) ? 1 : (indexSprite + 1);
      }, speed);

      sprite.intervalId = intervalId;
    },
    clearListener() {
      clearTimeout(this.hideTimeout);
      this.hideTimeout = null;
      const spriteSheet = document.querySelectorAll('.prize');
      if (!spriteSheet || !spriteSheet.length) { return; }

      spriteSheet.forEach((sprite) => {
        sprite.classList.add('prize-coin_1');
        sprite.style.visibility = 'hidden';
        if (sprite.lastClassAdded) {
          sprite.classList.remove(sprite.lastClassAdded);
        }
        if (sprite.intervalId) {
          clearInterval(sprite.intervalId);
          sprite.intervalId = null;
        }
      });
    },
  },
};
</script>
